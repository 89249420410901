import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "payment-statement-creation" }, [_vm._m(0), _c("div", [_c("div", { staticClass: "payment-statement-creation__body" }, [_c("div", { staticClass: "payment-statement-creation__body-left" }, [_c("div", { staticClass: "p-4" }, [_c("div", { staticClass: "body-left__select" }, [_c("select-input", { attrs: { "source": "year", "source-label": _vm.generateLabel, "data-source": _vm.listYear, "value": parseInt(_vm.selectedYear) || "", "default-active-first-option": "" }, on: { "change": _vm.changeYear } })], 1), _vm.listMonth ? _c("div", { staticClass: "body-left__list-month mt-5" }, _vm._l(_vm.listMonth, function(item, index2) {
    return _c("p", { key: index2, staticClass: "month-item", class: { selected: _vm.parseMonth(item.month) == _vm.selectedMonth }, on: { "click": function($event) {
      return _vm.chooseMonth(item.month);
    } } }, [_vm._v(" " + _vm._s(("0" + item.month).slice(-2) + "/" + _vm.selectedYear) + " ")]);
  }), 0) : _vm._e(), _c("div", { staticClass: "mt-4" })])]), _c("div", { staticClass: "payment-statement-creation__body-right p-5" }, [_c("router-view")], 1)])])]);
};
var staticRenderFns$1 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "d-flex payment-statement-creation__header justify-content-between align-items-center px-5 pt-2 pb-3" }, [_c("h2", { staticClass: "mb-0" }, [_vm._v("\u81EA\u52D5\u751F\u6210-\u652F\u6255\u660E\u7D30\u66F8\u4F5C\u6210")])]);
}];
var PaymentStatementCreation_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".payment-statement-creation[data-v-2ed727b8]{position:relative}.payment-statement-creation__header[data-v-2ed727b8]{background:#fff;color:#000;box-shadow:inset 0 -1px #eaedf0}.payment-statement-creation__body[data-v-2ed727b8]{display:flex;position:inherit}.payment-statement-creation__body-left[data-v-2ed727b8]{min-height:calc(100vh - 157px);background-color:#fff;min-width:220px}.payment-statement-creation__body-right[data-v-2ed727b8]{width:calc(100% - 220px);min-height:calc(100vh - 223px)}.payment-statement-creation .spinner-loading[data-v-2ed727b8]{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.body-left__select[data-v-2ed727b8]{width:95px}.body-left__list-month .month-item[data-v-2ed727b8]{padding-block:7px;padding-left:10px;cursor:pointer}.body-left__list-month .month-item[data-v-2ed727b8]:hover,.body-left__list-month .month-item.selected[data-v-2ed727b8]{background:#e5efff;border-right:2px solid #0062ff}\n")();
const __vue2_script$1 = {
  name: "PaymentStatementCreation",
  inject: ["resourceProps"],
  data() {
    const [yearsProps, monthsProps] = this.resourceProps;
    const { month, year } = this.$route.query;
    return {
      yearsProps,
      monthsProps,
      selectedYear: year || "",
      selectedMonth: month || ""
    };
  },
  computed: {
    listMonth() {
      const months = [...this.monthsProps.crud.getList()];
      if (months.length > 0 && months[0].monthOfYear) {
        return months[0].monthOfYear;
      }
      return months.length > 0 ? months : [];
    },
    listYear() {
      return this.yearsProps.crud.getList().sort((a, b) => {
        return b.year - a.year;
      });
    }
  },
  watch: {
    listMonth(newVal) {
      var _a, _b;
      if (newVal) {
        const defaultMonth = this.selectedMonth;
        if (!defaultMonth) {
          if (this.selectedYear == this.$moment().year())
            this.selectedMonth = (_a = newVal[newVal.length - 1]) == null ? void 0 : _a.month;
          else
            this.selectedMonth = (_b = newVal[0]) == null ? void 0 : _b.month;
          this.chooseMonth(this.selectedMonth);
        } else {
          this.chooseMonth(defaultMonth);
        }
      }
    }
  },
  async mounted() {
    await this.fetchYearList();
    if (this.$route.query.year) {
      await this.fetchMonthList();
    }
  },
  methods: {
    async fetchYearList() {
      await this.fetchList(this.yearsProps);
    },
    async fetchMonthList() {
      this.monthsProps.crud.deletePagination();
      this.monthsProps.crud.setFilter("Year", {
        operator: "Eq",
        value: this.selectedYear
      });
      await this.fetchList(this.monthsProps);
    },
    async fetchList(resourceProps) {
      resourceProps.crud.deleteFilter("IsInactive");
      await resourceProps.crud.fetchList();
    },
    async changeYear(value) {
      this.selectedYear = value;
      this.selectedMonth = "";
      await this.fetchMonthList();
    },
    generateLabel(option) {
      return option.year == this.$moment().year() ? "\u73FE\u5728" : option.year;
    },
    parseMonth(month) {
      return ("0" + month).slice(-2);
    },
    chooseMonth(selectedMonth) {
      this.selectedMonth = this.parseMonth(selectedMonth);
      const queryParamsUrl = {
        month: this.selectedMonth,
        year: this.selectedYear
      };
      const { month, year } = this.$route.query;
      if (month != this.selectedMonth || year != this.selectedYear) {
        this.$router.push({ name: "ReportFolders", query: queryParamsUrl });
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "2ed727b8", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var PaymentStatementCreation = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "jidoseisei.common.list-year" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": ["jidoseisei.common.list-year", "jidoseisei.common.list-month-of-year"] } }, [_c("payment-statement-creation")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { PaymentStatementCreation },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "\u81EA\u52D5\u751F\u6210-\u652F\u6255\u660E\u7D30\u66F8\u4F5C\u6210",
          title: "\u81EA\u52D5\u751F\u6210-\u652F\u6255\u660E\u7D30\u66F8\u4F5C\u6210",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
